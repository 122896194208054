import { lazy } from 'react';

const Make = lazy(() => import('@/components/apps/make'));
const Main = lazy(() => import('@/components/apps/app'));

function App() {
	/**
	 * Development application. A component generator
	 */
	if (process.env.REACT_APP_GENERATE === String(1)) return <Make />;

	/**
	 * Main application
	 */
	return <Main />;
}

export default App;
