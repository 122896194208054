import React from 'react';
import ReactDOM from 'react-dom';
import 'utils/theme/index.scss';
import 'react-notifications-component/dist/theme.css';
import App from 'App';
import '@/translations';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import Spinner from '@atlaskit/spinner';
import { Suspense } from 'react';
import 'tui-calendar/dist/tui-calendar.css';

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

ReactDOM.render(
	<Suspense fallback={<Preloader />}>
		<React.StrictMode>
			<App />
		</React.StrictMode>
	</Suspense>,
	document.getElementById('root'),
);

const app = initializeApp(firebaseConfig);
getAnalytics(app);

function Preloader() {
	return (
		<div
			style={{ height: '100vh' }}
			className="w-full flex items-center justify-center"
		>
			<Spinner size="xlarge" />
		</div>
	);
}
